export const FirebaseConfig = {
	"projectId": "easy-mobilidade-urbana",
	"appId": "1:998554002917:web:896a93d842de95713bfb0c",
	"databaseURL": "https://easy-mobilidade-urbana-default-rtdb.firebaseio.com",
	"storageBucket": "easy-mobilidade-urbana.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCAS6f44Ar9HG49r_H_ydenxTUubQ12o2k",
	"authDomain": "easy-mobilidade-urbana.firebaseapp.com",
	"messagingSenderId": "998554002917",
	"measurementId": "G-EDZM2NQ4ML"
};